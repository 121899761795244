<template>
  <v-form ref="form" lazzy-validation @submit.prevent="submit">
    <div v-if="update">
      <v-switch v-model="isEditFile" label="Modificar logo" />

      <v-file-input
        v-if="isEditFile"
        v-model="file"
        label="Logo"
        :rules="[(v) => !!v || 'El logo es obligatorio']"
        :error-messages="isValidFile"
        outlined
        required
        @change="submit()"
      />

      <v-img v-else :src="logoLink" height="194" />
    </div>

    <v-file-input
      v-if="!update"
      v-model="file"
      label="Adjuntar logo (png, jpg, jpeg, gif)"
      :rules="[(v) => !!v || 'El logo es obligatorio']"
      :error-messages="isValidFile"
      outlined
      required
      @change="submit()"
    />
  </v-form>
</template>

<script>
import BackendFile from "@/services/backendFile.service";

export default {
  props: {
    logoLink: {
      type: String,
      default: undefined,
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: {
        name: "",
        file: "",
      },
      isEditFile: false,
      dialog: false,
      item: null,
      isLoading: false,
      name: "",
      file: null,
      long_url: null,
    };
  },
  computed: {
    isValidFile() {
      return this.errors.file === undefined ? "" : this.errors.file[0];
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const formData = new FormData();

        if (this.file) {
          formData.append("logo", this.file);
        }

        if (!this.update) {
          BackendFile.post("/user/upload", formData)
            .then((response) => {
              if (response.data.success) {
                this.$emit("onUploadLogo", response.data.path);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
            });
        } else {
          BackendFile.post("/user/upload", formData)
            .then((response) => {
              if (response.data.success) {
                this.$emit("onUploadLogo", response.data.path);
              }
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
            });
        }
      }
    },
  },
};
</script>
