<template>
  <div>
    <v-row class="pa-8 px-8">
      <v-spacer></v-spacer>
      <v-btn class="mr-2" color="primary" dark @click="openNewUser()"
        ><v-icon>fa-plus</v-icon></v-btn
      >
    </v-row>
    <v-card>
      <v-card-text>
        <v-data-table :items="userN2" :headers="headers">
          <template v-slot:[`item.last_session`]="{ item }">
            {{ getLastSession(item) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" v-on="on"> Acciones </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="openUpdateUser(item)">
                  Modificar
                </v-list-item>
                <template>
                  <div class="text-center">
                    <v-dialog
                      v-model="dialogConfirm"
                      persistent
                      max-width="400"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item link v-bind="attrs" v-on="on">
                          Eliminar
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Seguro de eliminar este usuario?
                        </v-card-title>
                        <v-card-text
                          >Una vez eliminado este usuario, no podrá recuperarlo.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="dialogConfirm = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="primary"
                            dark
                            text
                            @click="deleteUser(item)"
                          >
                            Confirmo
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
                <template>
                  <div class="text-center">
                    <v-dialog
                      v-model="dialogConfirmPassword"
                      persistent
                      max-width="400"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item link v-bind="attrs" v-on="on">
                          Cambiar contraseña
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Esta seguro de cambiar contraseña?
                        </v-card-title>
                        <v-card-text
                          >Se va a generar una nueva contraseña aleatoria segura
                          para el usuario.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="dialogConfirmPassword = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="primary"
                            dark
                            text
                            @click="changePassword(item)"
                          >
                            Confirmo
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
                <template>
                  <div class="text-center">
                    <v-dialog
                      v-model="dialogConfirmLoginById"
                      persistent
                      max-width="400"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item link v-bind="attrs" v-on="on">
                          Iniciar sesión
                        </v-list-item>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Esta seguro de iniciar sesión?
                        </v-card-title>
                        <v-card-text
                          >Va a ingresar a la cuenta del usuario seleccionado
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="dialogConfirmLoginById = false"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="primary"
                            dark
                            text
                            :loading="loadingLoginById"
                            @click="loginById(item)"
                          >
                            Confirmo
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </template>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <NewUser ref="newUserComponent" @newUser="onCreatedUser" />

    <DialogPasswordComponent
      ref="dialogResetPassworh"
      @onOk="onResetPassword"
    />

    <div class="text-center ma-2">
      <v-snackbar v-model="snackbar">
        No se pudo realizar esta accion
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import BackendApi from "@/services/backend.service";
import NewUser from "./components/NewUserN2.vue";
import DialogPasswordComponent from "./components/DialogPasswordComponent.vue";
import moment from "moment";
export default {
  components: {
    NewUser,
    DialogPasswordComponent,
  },
  data() {
    return {
      snackbar: false,
      dialogConfirm: false,
      dialogConfirmPassword: false,
      dialogConfirmLoginById: false,
      loadingLoginById: false,
      userN2: [],
      headers: [
        {
          text: "Id",
          value: "id_usuario",
        },
        { text: "Nombre", value: "name" },
        { text: "Email", value: "email" },
        { text: "RUC", value: "ruc" },
        { text: "Ult. Sesión", value: "last_session" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
  mounted() {
    this.getUserN2();
  },
  methods: {
    openNewUser() {
      this.$refs.newUserComponent.open();
    },
    getUserN2() {
      BackendApi.get("/user/usersN2ForReport/" + this.$route.params.id)
        .then((response) => {
          if (response.data.success) {
            this.userN2 = response.data.data;
          }
        })
        .catch(() => {});
    },
    getLastSession(item) {
      return moment(item.last_session).format("DD/MM/YYYY hh:mm");
    },
    async deleteUser(item2) {
      const { data } = await BackendApi.delete("/user/user/" + item2.id);
      if (data.success) {
        this.dialogConfirm = false;
        this.getUsers();
      }
    },
    openUpdateUser(item) {
      this.$refs.newUserComponent.openUpdate(item);
      this.getUserN2();
      // this.dialogConfirm = true;
    },
    async changePassword(item) {
      const payload = {
        id_user: item.id,
      };
      const { data } = await BackendApi.post("/user/changePassword", payload);
      if (data.success) {
        this.$refs.dialogResetPassworh.open(
          data?.data?.email,
          data?.data?.password
        );
        this.dialogConfirmPassword = false;
        this.getUserN2();
      }
    },
    onCreatedUser() {
      this.getUserN2();
      this.$refs.newUserComponent.getUserN2();
    },
    onResetPassword() {
      this.$refs.dialogResetPassworh.close();
    },
    async loginById(item) {
      this.loadingLoginById = true;
      const payload = {
        user_id: item.id,
      };
      const { data } = await BackendApi.post("/auth/loginById", payload);
      if (data.success) {
        this.$cookies.set("token", data.token);
        this.$cookies.set("user", data.user);
        this.$cookies.set("isAdmin", data.isAdmin);
        this.$cookies.set("isSuperAdmin", data.isSuperAdmin);
        this.$cookies.set("color", data.user.brand_blank.color);
        this.$cookies.set("footer", data.user.brand_blank.footer);
        this.$cookies.set("logo", data.user.brand_blank.logo);
        this.$cookies.set(
          "agua",
          data.devices.some((device) => device.name === "agua")
        );
        this.$cookies.set(
          "energia",
          data.devices.some((device) => device.name === "energía")
        );
        this.$cookies.set(
          "gas",
          data.devices.some((device) => device.name === "gas")
        );
        this.dialogConfirmLoginById = false;
        this.loadingLoginById = false;
        window.location.reload();
      } else {
        this.snackbar = true;
      }
    },
  },
};
</script>

<style></style>
